li {
  list-style-type: square !important;
  margin-left: 2.5rem;
}

body {
  font-family: 'Calibri', 'Arial', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Calibri', 'Arial', sans-serif !important;
}

h5 {
  margin-bottom: 20px !important;
}

.poster_link {
  color: black; /* Link Text */
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(30, 30, 30); /* Primary Background */
    color: rgb(233, 233, 233); /* Primary Text */
  }

  .poster_link {
    color: rgb(233, 233, 233); /* Link Text */
  }
}
